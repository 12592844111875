import React, { useState } from "react";
import { Helmet } from "react-helmet-async";
import "../context/Css.css";
import { faq } from "../context/ConstantEN";

export default function SubPagePnResource(props) {
  const [searchKey, setSearchKey] = useState("");
  const [showList, setShowList] = useState(faq);
  const [foundAny, setFoundAny] = useState(true);

  const clearSearch = () => {
    setSearchKey((searchKey) => "");
    setFoundAny(true);
    setShowList(faq);
  };

  const startSearch = () => {
    const newItems = showList.filter((el) => el.q.includes(searchKey) || el.a.includes(searchKey));
    if (newItems.length > 0) {
      setShowList(newItems);
      setFoundAny(true);
    } else {
      setShowList([]);
      setSearchKey("");
      setFoundAny(false);
    }
  };

  return (
    <div className="pnBody">
      <Helmet>
        <title>Any user who registers a studio first time will win 90 man-days for free</title>
        <meta
          name="description"
          content="Mobile users need to download APP first; Users may search Help documents in sf2Pro,
          read training materials released by sf2Pro from time to time or create inquiries to sf2Pro Helpdesk"
        />
        <meta
          name="keywords"
          content="sf2Pro,Demand-Supply Collaboration,Professional Service,Management by Objectives,sf2Pro"
        />
      </Helmet>
      <div className="pnWrapperColumn">
        <div className="pnDescMain">
          <h3 className="secTitlePn">sf2Pro Registration</h3>
          <p className="secDescriptionPn" style={{ marginBottom: 16 }}>
            <br />
            Any user who registers studio <span className="secTitlePn">first time</span> will get 90 man-days free in
            their studio account
            <br />
            <br />
          </p>
          <a
            style={{ alignSelf: "center" }}
            href={
              ""
            }
            target={"_blank"}
            rel="noreferrer"
            id="userDownloadS5android"
            download
          >
            Android Download
          </a>
          <a
            style={{ alignSelf: "center" }}
            href="#"
            target={"_blank"}
            rel="noreferrer"
            id="userDownloadS5ios"
          >
            IOS Download
          </a>
          <a
            style={{ alignSelf: "center" }}
            href="#"
            target={"_blank"}
            rel="noreferrer"
            id="userRegisterS5Web"
          >
            Register on PC
          </a>
        </div>
      </div>

      <div className="pnWrapperColumn">
        <div className="pnDescMain">
          <h3 className="secTitlePn">Documents</h3>
          <div className="pnResourceSectionMat">
            <a
              className="pnDescResource2b"
              href={""}
              target={"_blank"}
              rel="noreferrer"
            >
              <img className="iconMiddleCenter" src={require("../assets/document.png")} alt="Overview"></img>
              <p className="secDescriptionPnFileName">Intro PPT</p>
            </a>

            <a
              className="pnDescResource2b"
              href={""}
              target={"_blank"}
              rel="noreferrer"
            >
              <img className="iconMiddleCenter" src={require("../assets/document.png")} alt="Overview"></img>
              <p className="secDescriptionPnFileName">Intro MP4</p>
            </a>

            <a
              className="pnDescResource2b"
              href={""}
              target={"_blank"}
              rel="noreferrer"
            >
              <img className="iconMiddleCenter" src={require("../assets/document.png")} alt="Technical Spec"></img>
              <p className="secDescriptionPnFileName">Tech Spec</p>
            </a>
          </div>
        </div>
      </div>

      <div className="pnWrapperColumn">
        <div className="pnDescMain">
          <div>
            <h3 className="secTitlePn">sf2Pro Help</h3>
            <p className="secDescriptionPn">
              Users may search Help documents in sf2Pro, read training materials which are released by sf2Pro from time
              to time, or consult sf2Pro Helpdesk.
            </p>
          </div>
          <img className="pnImageInSection" src={require("../assets/sfScreenHelpPcEN.png")} alt="sf2Pro Help"></img>

          <img className="pnImageInSectionS" src={require("../assets/sfScreenHelpPnPnEN.png")} alt="sf2Pro Help"></img>
          <div>
            <h3 className="secTitlePn">sf2Pro Always Accompanies You</h3>
            <p className="secDescriptionPn">
              For any topic most users are interested in, sf2Pro will try all possible ways e.g. by live streaming, to
              interact with the users.
            </p>
          </div>
        </div>
      </div>

      <div className="pnResourceSectionQ">
        <div className="pnDescMain">
          <h3 className="secTitlePn">Frequently Asked Questions</h3>
          <div className="pnFaqSearch">
            <input
              className="pnFaqSearchInput"
              type={"text"}
              placeholder={foundAny ? "Keywords" : "No record found, please retry"}
              value={searchKey}
              onChange={(input) => setSearchKey(input.target.value)}
            ></input>
            {(!!searchKey || !foundAny) && (
              <img
                className="iconSectionQundo"
                src={require("../assets/undo.png")}
                alt="Cancel"
                onClick={clearSearch}
              ></img>
            )}
            <img
              className="iconSectionQsearch"
              src={require("../assets/searchRight.png")}
              alt="Search"
              onClick={startSearch}
            ></img>
          </div>
          <div className="pnFaqItems">
            {showList.map((el) => {
              return (
                <div key={el.q}>
                  <p className="faqQuestion">{el.q}</p>
                  <p className="faqAnswer">{el.a}</p>
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="pnWrapperColumn">
        <p className="secDescriptionPn">
          <br />
          <br />
          <br />
        </p>
      </div>
    </div>
  );
}
