import React from "react";
import { Helmet } from "react-helmet-async";
import "../context/Css.css";

export default function SubPagePnProduct(props) {
  return (
    <div className="pnBody">
      <Helmet>
        <title>体验专业、感受服务、基于数字化场景了解项日葵</title>
        <meta
          name="description"
          content="项日葵的典型应用场景包括：组建业务团队、创建业务规则、开启服务过程、关注业务动态、业务回顾和分析和工作室管理"
        />
        <meta name="keywords" content="项日葵,服务管理,目标管理,sf2Pro" />
      </Helmet>
      <div className="pnDescMainLeft">
        <h3 className="secTitlePnLeft">业务场景示例</h3>
        <p className="secDescriptionPnLeft">体验专业、感受服务、基于数字化场景了解项日葵</p>
      </div>

      <div className="pnWrapperColumnLeft">
        <div className="pnWrapperRowInside">
          <div style={{ width: "45%" }}>
            <h3 className="secTitlePnLeft">业务场景1：组建业务团队</h3>
            <p className="secDescriptionPnLeft">
              组建团队从创建工作室开始，注册成功后管理员可邀请客户、供应商、同事、上级或下属加入工作室
            </p>
          </div>
          <img
            style={{ width: "50%", borderRadius: 16 }}
            src={require("../assets/sfScreenRmRegistApn.jpg")}
            alt="组建团队"
          ></img>
        </div>
        <p className="secDescriptionPnLeft">
          团队成员可以是服务合同中的甲乙方（如咨询顾问和顾客，代账会计师和代账委托人等），也可以是公司或组织内部成员（如产品研发中的需求代表和研发工程师，销售总监和交付团队）
        </p>
      </div>

      <div className="pnWrapperColumnLeft" style={{ marginTop: 16 }}>
        <div style={{ width: "100%" }}>
          <h3 className="secTitlePnLeft">工作室中的信任源于开放</h3>
          <p className="secDescriptionPnLeft">项日葵把供需双方拉到一个“屋檐”下，是为了让团队直接沟通并在“桌面”上合作</p>
        </div>

        <img
          style={{ width: "70%", marginTop: 16, marginBottom: 16, borderRadius: 8 }}
          src={require("../assets/sfScreenInvite.png")}
          alt="组建团队"
        ></img>
        <p className="secDescriptionPnLeft">
          采用这种形式是想解决服务领域的常见问题，如模糊、套路、误解、缺乏信任等 -
          项日葵认为，专业的团队首先需要干净的工作空间
        </p>
      </div>

      <div className="pnWrapperColumnLeft">
        <div style={{ width: "100%" }}>
          <h3 className="secTitlePnLeft">业务场景2：创建业务规则</h3>
          <p className="secDescriptionPnLeft">服务团队最怕含糊和扯皮，需求方和供给方常常指责对方不守规矩或变换莫测</p>
        </div>
        <img
          style={{ width: "70%", marginTop: 16, marginBottom: 16, borderRadius: 16 }}
          src={require("../assets/sfScreenConfig.png")}
          alt="业务规则"
        ></img>
        <p className="secDescriptionPnLeft">
          项日葵解决这个问题也从组建团队开始，比如建工作室时就指明对交付成果是否要引入专家评价，评价结果又如何影响服务满意度...
        </p>
      </div>

      <div className="pnWrapperColumnLeft" style={{ marginTop: 16 }}>
        <div style={{ width: "100%" }}>
          <h3 className="secTitlePnLeft">团队需要规则如同游戏需要约定</h3>
          <p className="secDescriptionPnLeft">项日葵为21个现代服务业的用户服务（行业清单请参见工作室注册页）</p>
        </div>

        <img
          style={{ width: "70%", marginTop: 16, marginBottom: 16, borderRadius: 8 }}
          src={require("../assets/sfScreenConfigDetail.jpg")}
          alt="业务规则"
        ></img>
        <p className="secDescriptionPnLeft">
          项日葵为这些用户提供众多的配置选项，包括：哪些业务内容需要供需双方事先确认、如何对服务产出进行评价和验收、对业务动态中的风险如何预警、如何评价团队和个人的绩效…
        </p>
      </div>

      <div className="pnWrapperColumnLeft">
        <div className="pnWrapperRowInside">
          <div style={{ width: "45%" }}>
            <h3 className="secTitlePnLeft">业务场景3：开启服务过程</h3>
            <p className="secDescriptionPnLeft">
              服务是层层递进的，持续迭代和细化的需求和方案还能不断地衍生出各种不同的任务、问题和风险
            </p>
          </div>
          <img
            style={{ width: "45%", borderRadius: 16 }}
            src={require("../assets/sfScreenReqCreateApn.jpg")}
            alt="创建需求"
          ></img>
        </div>
        <p className="secDescriptionPnLeft">
          服务的这种复杂性要求对信息进行细致分类和管理 -
          项日葵采用多媒体结构保存业务对象，主要目的是：避免当事人的表达不清或理解偏差、提高信息录入效率（用图片、视频、音频等方式描述业务有时更快也更准）
        </p>
        <div className="pnWrapperRowInside" style={{ alignItems: "flex-start" }}>
          <img
            style={{ width: "45%", borderRadius: 16 }}
            src={require("../assets/sfScreenObjCreateApn.jpg")}
            alt="创建业务凭证"
          ></img>
          <div style={{ width: "45%" }}>
            <p className="secDescriptionPnLeft">
              同时，对业务对象的任何更改都会在项日葵中产生一个新的版本 -
              项日葵的全版本管理将帮助供需双方回避选择性记忆和因之引起的争执
            </p>
          </div>
        </div>
      </div>

      <div className="pnWrapperColumnLeft">
        <div style={{ width: "100%" }}>
          <h3 className="secTitlePnLeft">业务场景4：关注业务动态</h3>
          <p className="secDescriptionPnLeft">
            项日葵的信息传递是实时和具体的。比如一个任务在验收时出现了问题，执行当事人、所关联的方案或需求责任人、团队负责人或所有工作室成员都可以被通知到
          </p>
        </div>
        <img
          style={{ width: "70%", marginTop: 16, borderRadius: 8 }}
          src={require("../assets/sfScreenTask.png")}
          alt="任务总览"
        ></img>
      </div>

      <div className="pnWrapperColumnLeft" style={{ marginTop: 16 }}>
        <div className="pnWrapperRowInside">
          <img
            style={{ width: "45%", borderRadius: 16 }}
            src={require("../assets/sfScreenReqDisplayApn.jpg")}
            alt="显示需求"
          ></img>
          <div style={{ width: "50%" }}>
            <h3 className="secTitlePnRight">捋清业务关联才能做到运筹帷幄</h3>
            <p className="secDescriptionPnLeft">
              一个需求可能依赖多个方案，每个方案又通过数个任务实现，有几个任务在执行时出现了问题、有一些风险隐患被需方责任人发现
            </p>
          </div>
        </div>
        <p className="secDescriptionPnLeft">
          项日葵通过对象网络帮助用户梳理这些业务对象，包括它们之间的关系。项日葵还可以按照用户定义的规则向关联责任人报警，并支持用户按网络方式检索关联对象...
        </p>
      </div>

      <div className="pnWrapperColumnLeft">
        <div style={{ width: "100%" }}>
          <h3 className="secTitlePnLeft">业务场景5：业务回顾和分析</h3>
          <p className="secDescriptionPnLeft">
            项日葵的看板式报表用以向用户汇报最新业务动态，项日葵也提供版本查询帮助用户追溯业务对象的变更历史
          </p>
        </div>
        <img
          style={{ width: "90%", marginTop: 16, borderRadius: 16 }}
          src={require("../assets/sfScreenDoA.png")}
          alt="目标达成"
        ></img>
      </div>

      <div className="pnWrapperColumnLeft" style={{ marginTop: 16 }}>
        <div className="pnWrapperRowInside">
          <div style={{ width: "40%" }}>
            <h3 className="secTitlePnLeft">服务如歌，应由您和团队自己谱曲</h3>
            <p className="secDescriptionPnLeft">项日葵用灵动的音符展示团队的工作成果，用音符的抑扬反映交付波动</p>
          </div>
          <img
            style={{ width: "55%", borderRadius: 8 }}
            src={require("../assets/sfScreenIssueList.jpg")}
            alt="显示需求"
          ></img>
        </div>
        <p className="secDescriptionPnLeft">
          音符音调的高低取决于事先约定的规则和按规则计算的目标达成情况
          <br />
          <br />
          在项日葵中，任何团队成员都可以听到来自团队的声音，也可以听到外部专家的建议（这些“专家”在项日葵里叫“嘉宾”）-
          诚恳而不带偏见的反馈将成为项日葵用户及团队成长的基石...
        </p>
      </div>

      <div className="pnWrapperColumnLeft">
        <div style={{ width: "100%" }}>
          <h3 className="secTitlePnLeft">业务场景6：工作室管理</h3>
          <p className="secDescriptionPnLeft">
            项日葵为每个工作室提供专属的、隔离的数据空间
            <br />
            <br />
            团队成员可选择加入一个或多个工作室，管理员可创建工作室、关闭工作室、邀请团队成员或在工作室间转账。项日葵为每个工作室提供团队公告、周报等功能…
          </p>
        </div>
        <img
          style={{ width: "90%", marginTop: 16, borderRadius: 8 }}
          src={require("../assets/sfScreenRoomList.png")}
          alt="工作室清单"
        ></img>
      </div>

      <div className="pnWrapperRow">
        <img
          style={{ width: "42%", borderRadius: 16 }}
          src={require("../assets/sfScreenMyNetworkApn.jpg")}
          alt="我的网络"
        ></img>
        <div style={{ width: "53%" }}>
          <h3 className="secTitlePnRight" style={{ textAlign: "right" }}>
            网络就是您的财富
          </h3>
          <p className="secDescriptionPnRight" style={{ textAlign: "right" }}>
            项日葵为每个用户记录职业网络，网络成员就是曾“与我相关”的工作室成员。这些成员或师或友、亦供亦需，是您的同道或同舟，也是您一生的财富…
          </p>
        </div>
      </div>

      <div className="pnWrapperRowRight">
        <p className="pcDirect" onClick={() => props.goResource()}>
          注册项日葵
        </p>
      </div>
    </div>
  );
}
