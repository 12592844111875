import React from "react";
import { Helmet } from "react-helmet-async";
import "../context/Css.css";

export default function SubPagePcProductEN(props) {
  return (
    <div className="pcBody">
      <Helmet>
        <title>Understand sf2Pro by Business Scenarios</title>
        <meta
          name="description"
          content="Typical sf2Pro scenarios include: Setup Team, Define Business Rules, Start Delivery, Monitor Status, Review and Analysis and Studio Management"
        />
        <meta
          name="keywords"
          content="sf2Pro,Demand-Supply Collaboration,Professional Service,Management by Objectives,sf2Pro"
        />
      </Helmet>
      <div className="pcProdSection0">
        <h3 className="secTitle">sf2Pro - Sunflower to Professionals - Scenario Illustration</h3>
        <p className="secDescription">Understand sf2Pro by Business Scenarios</p>
      </div>

      <div className="pcProdSection1">
        <div className="pcDescProd1a">
          <h3 className="secTitle">Scenario 1: Setup Teams</h3>
          <p className="secDescription">
            Setting up a team starts from a studio registration. Studio Admin may invite clients, suppliers, colleagues
            or supervisors to join her/his studio.
          </p>
        </div>

        <img className="pcPicProd1a" src={require("../assets/sfScreenInviteEN.png")} alt="Team setup"></img>

        <div className="pcDescProd1b">
          <p className="secDescription">
            Team members could be contract parties (e.g., consultants, lawyers and clients) or those within an
            organization (e.g., product developers, marketing analysts, sales and delivery engineers).
          </p>
        </div>

        <img className="pcPicProd1b" src={require("../assets/sfScreenRmRegistAEN.png")} alt="Team invitation"></img>
        <div className="pcDescProd1c">
          <h3 className="secTitleRight">Build Trust through Openness</h3>
          <p className="secDescription" style={{ textAlign: "right" }}>
            sf2Pro interlinks Demand and Supply parties so that both teams can communicate and put things always on the
            table. sf2Pro is committed to building a clean working space for teams to resolve those known pain points in
            the service industry, such as misunderstanding, obfuscation, unwritten rules and lack of trust.
          </p>
        </div>
      </div>

      <div className="pcProdSection2">
        <div className="pcDescProd2a">
          <h3 className="secTitle">Scenario 2: Define Business Rules</h3>
          <p className="secDescription">
            A service team may struggle with obfuscation and buck-passing. sf2Pro enables an evaluation mechanism to
            address this situation. The evaluator could be an internal or external expert, and the relevant evaluation
            type could be official or referential.
          </p>
        </div>

        <img className="pcPicProd2a" src={require("../assets/sfScreenConfigEN.png")} alt="Business rule"></img>

        <div className="pcDescProd2b">
          <h3 className="secTitleRight">Superior Teamwork under Game Rules</h3>
          <p className="secDescription" style={{ textAlign: "right" }}>
            sf2Pro business templates cover 21 service divisions (refer to the division list during studio
            registration). And the relevant studio configs include:
            <br />
            <br />
            Consensus items on collaboration
            <br />
            Criteria on deliverable evaluation
            <br />
            Rules on auto risk identification
            <br />
            Rules on performance calculation
            <br />
          </p>
        </div>
        <img className="pcPicProd2b" src={require("../assets/sfScreenConfigDetailEN.png")} alt="Studio config"></img>
      </div>

      <div className="pcProdSection3">
        <div className="pcDescProd3a">
          <h3 className="secTitle">Scenario 3: Start Deliveries</h3>
          <p className="secDescription">
            Requirements and solutions are expanded with various tasks, issues and risks.
            <br />
            <br />
            Service practitioners ask for precise information classification and utilization. sf2Pro leverages
            multi-media data structure to help teams:
            <br />
            <br />
            - Avoid ambiguous object descriptions
            <br />
            - Improve data input efficiency
            <br />
            <br />
            Meanwhile, sf2Pro full version management is applied to help teams track object histories and minimize
            conflicts caused by unreliable memories.
          </p>
        </div>

        <img
          className="pcPicProd3a"
          src={require("../assets/sfScreenObjCreateAEN.png")}
          alt="Create business document"
        ></img>
        <img className="pcPicProd3b" src={require("../assets/sfScreenReqCreateAEN.png")} alt="Create requirement"></img>
      </div>

      <div className="pcProdSection4">
        <div className="pcDescProd4a">
          <h3 className="secTitle">Scenario 4: Monitor Statuses</h3>
          <p className="secDescription">
            sf2Pro distributes information in details in real time. All stakeholders, e.g., task owners, issue
            responsible users, could be aligned through required status updates.
          </p>
        </div>

        <img className="pcPicProd4a" src={require("../assets/sfScreenTaskEN.png")} alt="Task overview"></img>

        <div className="pcDescProd4b">
          <h3 className="secTitleRight">Business Successes Based on Associations</h3>
          <p className="secDescription" style={{ textAlign: "right" }}>
            One requirement could be associated with multiple solutions or vise versa. One solution could be realized by
            several tasks. A risk could be identified if it would impact any specific requirement or solution.
            <br />
            <br />
            sf2Pro leverages a network to manage service objects and their associations. Auto alerts could be triggered
            and sent to stakeholders when the pre-defined conditions have been met.
          </p>
        </div>

        <img
          className="pcPicProd4b"
          src={require("../assets/sfScreenReqDisplayAEN.png")}
          alt="Display requirement"
        ></img>
      </div>

      <div className="pcProdSection5">
        <div className="pcDescProd5a">
          <h3 className="secTitle">Scenario 5: Review and Analysis</h3>
          <p className="secDescription">
            sf2Pro reports object statuses in Kanbans. Historical statuses can be tracked thanks to the full version
            management applied.
          </p>
        </div>

        <img className="pcPicProd5a" src={require("../assets/sfScreenDoAEN.png")} alt="Objective DOA"></img>

        <div className="pcDescProd5b">
          <h3 className="secTitleRight">Superb Songs Composed by Teams</h3>
          <p className="secDescription" style={{ textAlign: "right" }}>
            sf2Pro visualizes team performance and its fluctuation using music notes. The higher a music note is, the
            better the team DOA (Degree of Achievement). sf2Pro calculates DOAs based on rules which are defined by
            studio Admins.
            <br />
            <br />
            Each member might be evaluated by either an external expert (called Guest in sf2Pro) or an internal SME. The
            fair feedback from evaluators will be surely beneficial to team growth.
          </p>
        </div>

        <img className="pcPicProd5b" src={require("../assets/sfScreenIssueListEN.png")} alt="Issue list"></img>
      </div>

      <div className="pcProdSection6">
        <div className="pcDescProd6a">
          <h3 className="secTitle">Scenario 6: Studio Management</h3>
          <p className="secDescription">
            sf2Pro provides teams a safe and isolated digital space.
            <br />
            <br />A team member can join one or more studios. Studio Admin can invite new members, close their studios
            or transfer balances to other studios. Studio Admin and members they authorizes can post team notices or
            newsletters.
          </p>
        </div>

        <img className="pcPicProd6a" src={require("../assets/sfScreenMyNetworkAEN.png")} alt="My network"></img>

        <div className="pcDescProd6b">
          <h3 className="secTitleRight">Network is Your Fortune</h3>
          <p className="secDescription" style={{ textAlign: "right" }}>
            sf2Pro helps you maintain your network without omission. The network nodes include studio members who ever
            worked with you as your demand/supply parties, evaluators/evaluatees or risk/objective sharers.
          </p>
        </div>

        <img className="pcPicProd6b" src={require("../assets/sfScreenRoomListEN.png")} alt="Studio list"></img>
        <p
          className="pcDirect"
          style={{ position: "absolute", right: 0, bottom: 0 }}
          onClick={() => props.goResource()}
        >
          Register Now
        </p>
      </div>
    </div>
  );
}
