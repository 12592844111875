import React from "react";
import { Helmet } from "react-helmet-async";
import "../context/Css.css";

export default function SubPagePcMainEN(props) {
  return (
    <div className="pcBody">
      <Helmet>
        <title>sf2Pro - Deliver Services Professionally and Efficiently</title>
        <meta
          name="description"
          content="sf2Pro framework is designed specially for service domains regarding Project and Non-project businesses; sf2Pro's target users include Parties involved in service contracts and Service teams within organizations"
        />
        <meta
          name="keywords"
          content="sf2Pro,Demand-Supply Collaboration,Professional Service,Management by Objectives,sf2Pro"
        />
      </Helmet>
      <div className="pcHomeSection1">
        <h3 className="secTitle">Demand-Supply Collaboration Platform for Professionals</h3>
        <p>
          <br />
        </p>
        <div className="pcMainDivRowCenter">
          <button onClick={() => props.goResource()}>Start Trial</button>

          {/* <a
            style={{ textDecoration: "none" }}
            href={""}
            target={"_blank"}
            rel="noreferrer"
          >
            <button id="buttonLight">Overview</button>
          </a> */}
        </div>
        <p>
          <br />
        </p>
        <img className="picMainA1" src={require("../assets/picMainA1.png")} alt="sf2Pro"></img>
        <p>
          <br />
          <br />
        </p>
        <p className="secDescriptionFirstPage">
          <span className="secTitleS">Walk with songs</span> sf2Pro accompanies you to compose your songs
          <br />
          <span className="secTitleS">Dance with tutors</span> sf2Pro helps you find true mentors and friends,
          witnessing your growth
        </p>
      </div>

      <div className="pcHomeSection2">
        <h3 className="secTitle">Deliver Services Professionally and Efficiently</h3>
        <div className="pcMainDivRow">
          <div className="pcDescMain2a">
            <div className="iconPlusTitleRow">
              <img className="iconSmall" src={require("../assets/coConfirmed.png")} alt="Consensus"></img>
              <h3 className="secTitle">Collaborate on Agreements</h3>
            </div>
            <p className="secDescription" style={{ textAlign: "center" }}>
              Based on service objects, sf2Pro encourages Demand and Supply parties to log business decisions and
              provides sufficient functionalities for teams to track subsequent implementations
            </p>
          </div>

          <img className="pcDescMain2ab" src={require("../assets/pc_desktop_dot.png")} alt="And"></img>

          <div className="pcDescMain2b">
            <div className="iconPlusTitleRow">
              <img className="iconMiddle" src={require("../assets/objective.png")} alt="Consensus"></img>
              <h3 className="secTitle">Succeed on Objectives</h3>
            </div>

            <p className="secDescription" style={{ textAlign: "center" }}>
              In service delivery processes, sf2Pro promotes a cycle of objective-action-feedback in order to guide
              teams to become more agile and result-oriented
            </p>
          </div>
        </div>
        <p className="pcDirect" style={{ alignSelf: "flex-end" }} onClick={() => props.goProduct()}>
          Learn More
        </p>
      </div>

      <div className="pcHomeSection31">
        <div className="secTitleSubTitle">
          <h3 className="secTitle">Facilitate Steady Service Deliveries</h3>
          <p className="secDescription">Help teams build trust in natural ways</p>
        </div>

        <div className="pcMainDivRow">
          <div className="pcDescMain3a">
            <h3 className="secTitle">Service Objects</h3>
            <br />
            <p className="secDescription">
              Service objects in sf2Pro are what practitioners have been familiar with:
              <span className="secTitleS"> Requirement, Solution, Task, Issue and Risk</span>
              <br />
              <br />
              sf2Pro helps teams refine relationships among service objects and alert relevant stakeholders any serious
              issues or risks.
              <br />
              <br />
              Meanwhile, sf2Pro applies collaboration and evaluation mechanisms to deliverables across the whole service
              object network.
            </p>
          </div>
          <img className="picMain31" src={require("../assets/picMain31.png")} alt="sf2Pro"></img>
        </div>

        <p className="pcDirect" style={{ alignSelf: "flex-end" }} onClick={() => props.goProduct()}>
          Learn More
        </p>
      </div>

      <div className="pcHomeSection32">
        <div className="secTitleSubTitle">
          <h3 className="secTitle">Improve Performance by Professionalism</h3>
          <p className="secDescription">Guide team collaboration towards harmony</p>
        </div>

        <div className="pcMainDivRow">
          <img className="picMain32" src={require("../assets/picMain32.png")} alt="Function Structure"></img>
          <div className="pcDescMain3b">
            <h3 className="secTitle">Functional Structure</h3>
            <br />
            <p className="secDescription" style={{ textAlign: "left" }}>
              sf2Pro framework is tailored for service domains which include:
              <br />
              <br />
              Project businesses
              <br />
              Non-project businesses
              <br />
              <br />
              <span className="secTitleS">sf2Pro's target users</span> are:
              <br />
              <br />
              Parties involved in service contracts
              <br />
              Service teams within organizations
              <br />
              <br />
              <span className="secTitleS"> Functional elements </span>include:
              <br />
              <br />
              Service object maintenance <br />
              Consensus among stakeholders <br />
              Evaluation on service deliverables <br />
              Association among service objects <br />
              Analysis and alert based on measures <br />
              Full version management <br />
              Studio and team management <br />
              System message and notice
            </p>
          </div>
        </div>

        <p className="pcDirect" style={{ alignSelf: "flex-end" }} onClick={() => props.goProduct()}>
          Learn More
        </p>
      </div>

      <div className="pcHomeSection4">
        <h3 className="secTitle">Advanced know-how about Service Industries</h3>
        <div className="pcMainDivRow">
          <p className="secDescription4">
            <span className="secTitleS">sf2Pro team </span>
            consists of very experienced designers and architects who have served Fortune 500 clients for more than 20
            years.
            <br /> <br />
            sf2Pro team has in-depth knowledge of service team management and desires to help service professionals to
            operate their businesses on a digital platform which is tailored for their needs.
          </p>

          <img className="pcPicMain4" src={require("../assets/picMain41.png")} alt="Product team"></img>
        </div>
      </div>

      <div className="pcHomeSection5">
        <h3 className="secTitle">Vision</h3>
        <p className="secDescription">
          sf2Pro's vision is to help Demand and Supply parties to build a team in which all members can work together in harmony, and happily build a team culture as follows:
        </p>
        <img className="pcPicMain5" src={require("../assets/picMain5EN.png")} alt="Product team"></img>

        <div className="pcMainDivColumnBlue">
          <p></p>
          <h3 className="secTitleWhitePn">Demand-Supply Collaboration Platform</h3>
          <p className="secDescriptionWhite">Register and get a welcome gift!</p>
          <button onClick={() => props.goResource()}>Start Trial</button>
          <p></p>
        </div>
      </div>
    </div>
  );
}
