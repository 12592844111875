import React from "react";
import { Helmet } from "react-helmet-async";
import "../context/Css.css";

export default function SubPagePnPrice(props) {
  return (
    <div className="pnBody">
      <Helmet>
        <title>项日葵 - 企业级服务、消费级价格</title>
        <meta
          name="description"
          content="项日葵工作室有体验间、标准间和豪华间三种；项日葵提供的是工作室租用服务，用户可按人天对此类服务付费或续费"
        />
        <meta name="keywords" content="项日葵,服务管理,目标管理,sf2Pro" />
      </Helmet>
      <div className="pnWrapperColumn">
        <div className="pnDescMain">
          <h3 className="secTitlePn">项日葵价格说明</h3>
          <p className="secDescriptionPn">
            企业级服务、消费级价格
            <br />
            <br />
            <br />
          </p>
          <h3 className="secTitlePn">工作室类型</h3>
          <p className="secDescriptionPn">项日葵的工作室有体验间、标准间和豪华间三种</p>
          <img className="pnImageInSection" src={require("../assets/meetingrmS.jpg")} alt="线上工作室"></img>
          <p className="secDescriptionPn">管理员（即创建工作室的用户）负责开启、运营、暂停运营或关闭工作室等操作</p>
        </div>
      </div>

      <div className="pnPriceSubSection">
        <div className="pnDescPrice1c">
          <h3 className="secTitleS">体验间</h3>
          <h3 className="secTitleS">免费</h3>
          <p className="secDescriptionScenter">
            赠送90人天
            <br />
            存储按实际使用折算
            <br />
            短信按实际使用折算
            <br />
            <br />
          </p>
        </div>
        <div className="pnDescPrice1c">
          <h3 className="secTitleS">标准间</h3>
          <h3 className="secTitleS">1.60 元/人天</h3>
          <p className="secDescriptionScenter">
            可用人天按充值计
            <br />
            200千兆天赠送存储
            <br />
            30次赠送短信
            <br />
            <br />
          </p>
        </div>
        <div className="pnDescPrice1c">
          <h3 className="secTitleS">豪华间</h3>
          <h3 className="secTitleS">1.60 元/人天/900人天起</h3>
          <p className="secDescriptionScenter">
            项日葵专属客服
            <br />
            900千兆天赠送存储
            <br />
            180次赠送短信
            <br />
            <br />
          </p>
        </div>

        <p className="secDescriptionPn" style={{ alignSelf: "flex-start", marginTop: 32 }}>
          更多信息请参见&nbsp;
          <a
            className="pnDirectInline"
            href={"https://cos-dev-public.sf2pro.com/files/sf2ProStudioTypeEN.pdf"}
            target={"_blank"}
            rel="noreferrer"
          >
            了解工作室类型
          </a>
        </p>
      </div>

      <div className="pnWrapperColumn">
        <div className="pnDescMain">
          <h3 className="secTitlePn">人天结算规则</h3>
          <p className="secDescriptionPn">
            项日葵提供的是工作室租用服务，用户可按人天对此类服务付费或续费
            <br />
            <br />
            管理员可暂停团队对工作室的访问（相关扣费也随之停止）
          </p>
          <img className="pnImageInSection" src={require("../assets/sfScreenInvoice.png")} alt="扣费规则"></img>
          <p className="secDescriptionPn" style={{ alignSelf: "flex-start", marginTop: 16 }}>
            详细扣费规则请点击&nbsp;
            <a
              className="pnDirectInline"
              href={"https://cos-dev-public.sf2pro.com/files/sf2ProStudioPricingEN.pdf"}
              target={"_blank"}
              rel="noreferrer"
            >
              了解扣费规则
            </a>
          </p>
        </div>
      </div>

      <div className="pnWrapperColumn">
        <div className="pnDescMain">
          <h3 className="secTitlePn">开票与转账</h3>
          <p className="secDescriptionPn">
            如果工作室账户有余额，管理员可将余额转至其它工作室
            <br />
            管理员在余额低于零之前续费，不需要时可关闭工作室
          </p>
          <img className="pnImageInSection" src={require("../assets/sfScreenAccTransfer.png")} alt="扣费规则"></img>
          <p className="secDescriptionPn" style={{ alignSelf: "flex-start", marginTop: 16 }}>
            项日葵支持电子发票和正当理由的退款
          </p>
        </div>
      </div>
    </div>
  );
}
